<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
    <section class="bg-color-">
      <div class="container">
        <template>
          <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
              <v-spacer></v-spacer>
              <template>
                <download-excel :data="fineData" :fields="all_org" worksheet="Books Data" name="Books Data.xls">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                    </template>
                    <span>Import to Excel</span>
                  </v-tooltip>
                </download-excel>
              </template>
            </v-card-title>
          
            <v-data-table :headers="headers" :items="fineData" :search="search">
              <template v-slot:item.fine="{ item, index }">
                <!-- <v-icon>mdi-currency-inr</v-icon><span style="font-size:18px">{{ item.fine }}</span> -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip color="gray" v-bind="attrs" v-on="on" dark @click="index !== fineData.length - 1 && getdetails(item)">
                      <v-icon>mdi-currency-inr</v-icon> <span style="font-size:18px">{{ item.fine }}</span>
                    </v-chip>
                  </template>
                  <span> View Details</span>
                </v-tooltip>
              </template>
            </v-data-table>
           
          </v-card> </div>
        </template>
      </div>
    </section>
    <v-dialog v-model="dialog_details" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="gray">
              <v-btn icon dark @click="dialog_details = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ organization_name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="dialog_details = false">
                  Close
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <br />
            <v-card-text>
              <v-card>
                <v-card-title>
                  <v-text-field v-model="search_" append-icon="mdi-magnify" label="Search" single-line
                    hide-details></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel :data="details_list" :fields="single_" worksheet="Employee Data"
                      name="Employee Data.xls">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                        </template>
                        <span>Import to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>
                <template>
                  <div class="text-center" v-if="circular">
                    <v-progress-linear indeterminate height="25" color="#393e46">
                      <strong style="color:#FFF;">Loading...</strong>
                    </v-progress-linear>
                  </div>
                </template>
                <v-data-table :headers="headers_" :items="details_list" :search="search_">
                  <template v-slot:item.receipt_no="{ item }">
                    <!-- <v-icon>mdi-currency-inr</v-icon><span style="font-size:18px">{{ item.fine }}</span> -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip color="gray" v-bind="attrs" v-on="on" dark @click="get_receipt_no_details(item)">
                           <span>{{ item.receipt_no }}</span>
                        </v-chip>
                      </template>
                      <span> View Receipt</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.program="{ item }">
                      <span v-if="item.program">{{ item.program }}</span>
                      <span v-else style="font-size: 20px; font-weight: bold;"> <center>-</center> </span>
                  </template>
                  <template v-slot:item.year="{ item }">
                      <span v-if="item.year">{{ item.year }}</span>
                      <span v-else style="font-size: 20px; font-weight: bold;"> <center>-</center> </span>
                  </template>
                  <template v-slot:item.division="{ item }">
                      <span v-if="item.division">{{ item.division }}</span>
                      <span v-else style="font-size: 20px; font-weight: bold;"> <center>-</center> </span>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="receipt_dialog">
            <v-card >     
                 <receipt_main :key="receipt_main_key" :recno="selected_receipt_no" 
                 ></receipt_main>
                 <center><v-btn @click="receipt_dialog = false" color="primary" class="mb-5">Close</v-btn></center>
            </v-card>
        </v-dialog>
    <!-- /. section -->
  </div>
  <!-- /. root div -->
</template>
<script>
import axios from "axios";
import receipt_main from "../../Librarian/receipt_main.vue";
export default {
  components: {receipt_main},
  data: () => ({
    init_loading: false,
    fineData: [],
    orgbookdetails: [],
    loading: true,
    snackbar_msg: "",
    receipt_dialog: false,
    selected_receipt_no: "",
    receipt_main_key: "",
    color: "",
    snackbar: false,
    search: "",
    search_book: "",
    org_name: null,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      // {
      //   text: 'Management Dashboard',
      //   disabled: false,
      //   href: '/management-dashboard',
      // },
      {
        text: 'Fine Collected',
        disabled: true,
        href: '',
      },
    ],
    dialog_details: false,
    headers: [
      { text: "Sr. No.", value: "srno" },
      { text: "Organization", value: "orgName" },
      { text: "Fine collected", value: "fine" },
    ],
    all_org: {
      "Sr. No.": "srno",
      Orgnization: "orgName",
      "Total Fine in Rs.": "fine",
    },
    circular: false,
    details_list: [],
    organization_name: "",
    search_: "",
    headers_ : [
      { text: "Sr. No.", value: "srno" },
      { text: "Name", value: "name" },
      { text: "User Type", value: "usertype" },
      { text: "Program", value: "program" },
      { text: "Division", value: "division", align: "center" },
      { text: "Year", value: "year", align: "center" },
      { text: "Receipt No", value: "receipt_no" },
      { text: "Amount", value: "amount" },
    ],
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.init_loading = true
      axios
        .post("/ManagementAdmin/getAllOrgFineData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.fineData = res.data.fineData;
            this.init_loading = false
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    getdetails(item) {
      const data = {
        org_id: item.org_id,
      };
      this.circular = true
      axios
        .post("/ManagementAdmin/getOrgFineCollectedDetails", data)
        .then((res) => {
          if (res.data.msg == "200" || res.data.msg == 200) {
            this.details_list = res.data.empdetails;
            this.organization_name = res.data.organization_name;
            this.dialog_details = true;
            this.circular = false
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    get_receipt_no_details(item){
      this.receipt_main_key++;
      this.receipt_dialog = true;
      this.selected_receipt_no = item.receipt_no;
    },
  },
  watch: {},
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1024px) {}
</style>
